@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

:root {
  --primary: #D75004;
  --secondary: #53BB57;
  --btn-bg: #000;
}

@layer base {
  html {
    @apply scroll-smooth 
  }
  body {
    @apply font-[Raleway] 
  }
  p {
    @apply pb-3 
  }
  button {
    @apply px-8 py-1  bg-[var(--btn-bg)] text-white rounded-full text-[14px] md:text-[16px] drop-shadow-sm hover:scale-105 
  }
} 

@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 10px;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #FDEBFF;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #f386ff;
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}